import type { CallToActionModel } from '~/models'

export const useCtaLink = async (cta: CallToActionModel) => {
  const email = ref(stripHtmlTags(cta?.elements?.email?.value))
  const phone = ref(stripHtmlTags(cta?.elements?.phoneNumber?.value))
  const genericAsset = ref(cta?.elements?.genericAssetTarget?.value)
  const linkedTarget = ref(cta?.elements?.linkedTarget?.value)
  const manualTarget = ref(stripHtmlTags(cta?.elements?.manualTarget?.value))
  if (linkedTarget?.value?.length > 0) {
    const codename = linkedTarget.value[0]
    const pageStore = usePageStore()
    return await pageStore.getUrlByCodename(codename)
  } else if (genericAsset?.value?.length > 0) {
    return genericAsset.value[0].url
  } else if (manualTarget?.value?.length > 0) {
    return manualTarget.value
  } else if (email?.value?.length > 0) {
    return `mailto:${email.value}`
  } else if (phone?.value?.length > 0) {
    return `tel:${phone.value}`
  }
  return ''
}
